<template>
	<div class="projects-filter-controls">
		<button
			v-for="item in filters"
			:key="item.label"
			:class="['filter-item', { active: item.value === filter }]"
			@click="onFilterSelected(item.value)"
		>
			{{ item.label }}
		</button>
	</div>
</template>

<script>
	export default {
		props: {
			filter: {
				type: String,
				default() {
					return null;
				}
			}
		},
		data() {
			return {
				filters: [
					{
						label: 'All',
						value: null
					},
					{
						label: 'Web',
						value: 'web'
					},
					{
						label: 'Desktop',
						value: 'desktop'
					},
					{
						label: 'Game',
						value: 'game'
					},
					{
						label: 'Chat',
						value: 'chat'
					}
				]
			};
		},
		methods: {
			/**
			 * Emits the "change" event with the selected filter
			 * @param {String} filter
			 */
			onFilterSelected(filter) {
				this.$emit('change', filter);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.projects-filter-controls {
		padding: 0px 10px;
		margin-bottom: 10px;
		text-align: center;

		.filter-item {
			padding: 7px 14px;
			border-radius: 3px;
			border: none;
			background-color: $white;
			color: $gray-medium-dark;
			font-weight: bold;
			font-size: 14px;
			transition: all 0.4s ease-in-out;
			cursor: pointer;

			+ .filter-item {
				margin-left: 5px;
			}

			&.active, &:hover {
				background-color: $gray-medium;
				color: $white;
			}
		}

		@media (max-width: $medium-small) {
			.filter-item {
				padding: 5px 7px;
			}
		}
	}
</style>
