<template>
	<div class="projects-list">
		<div class="projects-list-title">
			Projects
		</div>
		<transition name="slide">
			<div
				v-show="projectsListIsVisible"
				ref="projectsList"
				class="projects-list-inner"
			>
				<ProjectsFilterControls
					:filter="filter"
					@change="onFilterChanged"
				/>

				<transition-group
					class="projects"
					name="projects"
					tag="div"
				>
					<div
						v-for="project in projects"
						class="project-wrapper"
						:key="project.title"
					>
						<ProjectsListItem
							:project="project"
							@click="openProject"
						/>
					</div>
				</transition-group>
			</div>
		</transition>
	</div>
</template>

<script>
	import ProjectsFilterControls from '@/components/projects-list/ProjectsFilterControls';
	import ProjectsListItem from '@/components/projects-list/ProjectsListItem';

	export default {
		components: {
			ProjectsFilterControls,
			ProjectsListItem
		},
		props: {
			projects: {
				type: Array,
				default() {
					return [];
				}
			},
			filter: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				projectsListIsVisible: false
			};
		},
		/**
		 * Attaches the scroll event listener
		 */
		mounted() {
			document.addEventListener('scroll', this.onPageScroll);
		},
		/**
		 * Removes the scroll event listener
		 */
		beforeDestroy() {
			this.removeScrollListener();
		},
		methods: {
			/**
			 * Emits the "filter-projects" event with the selected filter
			 * @param {String} filter
			 */
			onFilterChanged(filter) {
				this.$emit('filter-projects', filter);
			},
			/**
			 * Emits the "open-project" event with the selected project
			 * @param {Object} project
			 */
			openProject(project) {
				this.$emit('open-project', project);
			},
			/**
			 * Removes the scroll event listener
			 */
			removeScrollListener() {
				document.removeEventListener('scroll', this.onPageScroll);
			},
			/**
			 * Shows the projects list when the user scrolls into the projects list view port
			 */
			onPageScroll() {
				if (!this.$refs.projectsList) {
					return;
				}

				const rect = this.$refs.projectsList.getBoundingClientRect();
				const inViewPort = rect.top < window.innerHeight;

				if (!this.projectsListIsVisible && inViewPort) {
					this.projectsListIsVisible = true;
					this.removeScrollListener();
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.projects-list {
		position: relative;
		min-height: 100vh;
		padding-bottom: 20px;
		background-color: rgba($black, 0.7);

		.projects-list-title {
			padding: 40px 0px;
			text-align: center;
			font-size: 36px;
			font-weight: bold;
			text-transform: uppercase;
		}

		.slide-enter-active, .slide-leave-active {
			transition:
				transform 0.8s ease,
				opacity 3s ease;
		}

		.slide-enter, .slide-leave-to {
			opacity: 0;
			transform: translateY(800px);
		}

		.projects-list-inner {
			margin: auto;
			width: 90%;
			max-width: 1000px;

			.projects {
				position: relative;
				display: flex;
				flex-wrap: wrap;

				.project-wrapper {
					width: 33.33%;
					padding: 10px;
				}
			}

			.projects-leave-active {
				display: none;
			}

			.projects-move {
				transition: all 0.5s ease-out;
			}

			@media (max-width: $medium) {
				.projects {
					.project-wrapper {
						width: 50%;
					}
				}
			}

			@media (max-width: $small) {
				width: 100%;
				padding: 0px 10px;
			}

			@media (max-width: $medium-small) {
				width: 80%;

				.projects {
					.project-wrapper {
						width: 100%;
					}
				}
			}

			@media (max-width: $extra-small) {
				width: 100%;

				.projects {
					.project-wrapper {
						width: 100%;
					}
				}
			}
		}
	}
</style>
