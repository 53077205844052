import Vue from 'vue';
import Vuex from 'vuex';
import config from '@/config';
import projectsData from '@/assets/projects.json';

Vue.use(Vuex);

//set the full images path using configured project images path
const projects = projectsData.map((project) => {
	const images = project.images.map((image) => {
		return [config.projectImagesPath, image].join('');
	});

	return {
		...project,
		images
	};
});

export default new Vuex.Store({
	state: {
		projects,
		filter: null
	},
	getters: {
		/**
		 * Returns all the projects that match the selected filter
		 * @param {Object} state
		 * @returns {Array}
		 */
		projects(state) {
			if (!state.filter) {
				return state.projects;
			}

			return state.projects.filter((project) => {
				return project.tags.includes(state.filter);
			});
		}
	},
	mutations: {
		/**
		 * Sets the projects state
		 * @param {Object} state
		 * @param {Array} projects
		 */
		SET_PROJECTS(state, projects) {
			state.projects = projects;
		},
		/**
		 * Sets the project filter
		 * @param {Object} state
		 * @param {String} filter
		 */
		SET_FILTER(state, filter) {
			state.filter = filter;
		}
	},
	actions: {
		/**
		 * Sets the projects filter
		 * @param {Object} context
		 * @param {String} filter
		 */
		filterProjects(context, filter) {
			context.commit('SET_FILTER', filter);
		},
		/**
		 * Opens the project info modal with the selected project data
		 * @param {Object} context
		 * @param {Object} project
		 */
		openProjectInfoModal(context, project) {
			this._vm.$modal.show('project-info-modal', project);
		},
		/**
		 * Closes the project info modal
		 * @param {Object} context
		 */
		closeProjectInfoModal(context) {
			this._vm.$modal.hide('project-info-modal');
		}
	}
});
