<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		/**
		 * Bootstrap the app once it's created
		 */
		created() {
			//prevent the browser for caching the scroll position on page reload
			window.history.scrollRestoration = 'manual';
		}
	};
</script>

<style lang="scss">
	*,
	:after,
	:before {
		box-sizing:border-box;
	}

	body, html {
		margin: 0;
		padding: 0;
		height: 100%;
	}

	body {
		font-family:  $font-family;
		color: $text-color;
		line-height: 1.2;
		background-color: $gray-dark;

		:focus {
			outline: none;
		}

		::-moz-focus-inner {
			border: 0;
		}

		input {
			filter: none;
		}

		button {
			&:focus {
				outline: none;
			}
		}
	}
</style>
