import Vue from 'vue';
import VModal from 'vue-js-modal';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import App from './App.vue';
import store from './store';
import router from './router';

//add more icons if necessary
library.add(faGithub, faLinkedin, faChevronDown, faExternalLinkAlt);

//enables the use of the <i> tag for the font awesome icons (<i class="fab fa-apple"></i>)
dom.watch();

/* how to use the font-awesome vue component:
	<font-awesome-icon :icon="['fas', 'coffee']" />
	<font-awesome-icon :icon="['far', 'angry']" />
	<font-awesome-icon :icon="['fab', 'apple']" />
*/

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VModal);

//register all project templates as global components
const requireComponent = require.context('./components/project-templates', false, /\w+\.vue$/);
requireComponent.keys().forEach((fileName) => {
	const componentConfig = requireComponent(fileName);
	const componentName = fileName
		.split('/')
		.pop()
		.replace(/\.\w+$/, '');

	Vue.component(componentName, componentConfig.default);
});

Vue.config.productionTip = false;

new Vue({
	store,
	router,
	render: (h) => h(App)
}).$mount('#app');
