<template>
	<div class="intro">
		<transition appear name="fade">
			<div class="inner-wrapper">
				<div class="header">
					Plamen Ivanov
				</div>
				<div class="subheader">
					A Full-Stack Developer
				</div>

				<div class="contact-links">
					<IconLink
						href="https://github.com/gryp17"
						title="GitHub"
						target="_blank"
					>
						<font-awesome-icon :icon="['fab', 'github']" />
					</IconLink>
					<IconLink
						href="https://www.linkedin.com/in/gryp17-ab297955"
						title="Linkedin"
						target="_blank"
					>
						<font-awesome-icon :icon="['fab', 'linkedin']" />
					</IconLink>
				</div>

				<TransparentButton
					title="Browse projects"
					@click="$emit('show-projects')"
				>
					<font-awesome-icon :icon="['fas', 'chevron-down']" />
					Browse projects
				</TransparentButton>
			</div>
		</transition>
	</div>
</template>

<script>
	import IconLink from '@/components/IconLink';
	import TransparentButton from '@/components/TransparentButton';

	export default {
		components: {
			IconLink,
			TransparentButton
		}
	};
</script>

<style lang="scss" scoped>
	.intro {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100vh;

		.fade-enter-active, .fade-leave-active {
			transition: all 0.8s ease-out;
		}

		.fade-enter, .fade-leave-to {
			opacity: 0;
		}

		.inner-wrapper {
			position: relative;
			padding: 0px 10px;
			text-align: center;

			.header {
				font-size: 50px;
				font-weight: bold;
				text-transform: uppercase;
			}

			.subheader {
				margin-top: 5px;
				font-size: 34px;
			}

			.contact-links {
				padding: 20px 0px;

				.icon-link {
					& + .icon-link {
						margin-left: 5px;
					}
				}
			}

			.transparent-button {
				margin: auto;
			}
		}

		@media (max-width: $small) {
			.inner-wrapper {
				.header {
					font-size: 44px;
				}

				.subheader {
					font-size: 30px;
				}

				.transparent-button {
					font-size: 20px;
				}
			}
		}
	}
</style>
