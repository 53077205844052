import Vue from 'vue';
import VueRouter from 'vue-router';

import Main from '@/views/Main';
import Project from '@/views/Project';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		component: Main,
		children: [
			{
				path: '/project/:id',
				name: 'project',
				component: Project
			}
		]
	},
	{
		path: '*',
		redirect: '/'
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
