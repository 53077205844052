<template>
	<ProjectInfoModal
		@close="closeProjectInfoModal"
	/>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import ProjectInfoModal from '@/components/ProjectInfoModal';

	export default {
		components: {
			ProjectInfoModal
		},
		watch: {
			/**
			 * Watches the route and opens the project modal
			 */
			$route() {
				this.openProject();
			}
		},
		/**
		 * Opens the project info modal once the component is mounted
		 */
		mounted() {
			this.openProject();
		},
		computed: {
			...mapState([
				'projects'
			]),
			/**
			 * Returns the project that matches the route project id
			 * @returns {Object}
			 */
			project() {
				return this.projects.find((project) => {
					return project.class === this.$route.params.id;
				});
			}
		},
		methods: {
			...mapActions([
				'openProjectInfoModal',
				'closeProjectInfoModal'
			]),
			/**
			 * Opens the project info modal with the selected project data
			 */
			openProject() {
				if (this.project) {
					this.openProjectInfoModal(this.project);
				}
			}
		}
	};
</script>
