<template>
	<div class="project-info-modal">
		<modal
			name="project-info-modal"
			:adaptive="true"
			:scrollable="true"
			:width="'100%'"
			:maxWidth="900"
			:height="'auto'"
			:clickToClose="true"
			@before-open="onBeforeOpen"
			@before-close="onBeforeClose"
		>
			<div
				v-if="project"
				:style="contentWrapperStyles"
				class="modal-content"
			>
				<SplideCarousel
					:options="carouselOptions"
				>
					<img
						v-for="image in images"
						:key="image"
						:src="image"
						class="splide__slide"
					/>
				</SplideCarousel>

				<div
					class="project-title"
					:style="titleStyles"
				>
					{{ title }}
				</div>

				<ProjectDescription
					:project="project"
				/>

				<div :class="['buttons-wrapper', { 'apple-sucks': this.isIOS }]">
					<button
						title="Close"
						class="close-btn"
						@click="closeModal"
					>
						Close
					</button>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
	import SplideCarousel from '@/components/SplideCarousel';
	import ProjectDescription from '@/components/ProjectDescription';

	export default {
		components: {
			SplideCarousel,
			ProjectDescription
		},
		data() {
			return {
				project: null
			};
		},
		computed: {
			/**
			 * Contains the content wrapper styles that are calculated using the project data
			 * @returns {Object}
			 */
			contentWrapperStyles() {
				return {
					borderColor: this.project.background
				};
			},
			/**
			 * Contains the title styles that are calculated using the project data
			 * @returns {Object}
			 */
			titleStyles() {
				return {
					backgroundColor: this.project.background,
					color: this.project.color
				};
			},
			/**
			 * Contains the project images
			 * @returns {Array}
			 */
			images() {
				return this.project.images;
			},
			/**
			 * Indicates whether the carousel should have arrows
			 * @returns {Boolean}
			 */
			showCarouselArrows() {
				return this.images.length > 1;
			},
			/**
			 * Contains the splide carousel options
			 * @returns {Object}
			 */
			carouselOptions() {
				return {
					rewind: true,
					autoplay: true,
					interval: 3000,
					arrows: this.showCarouselArrows
				};
			},
			/**
			 * Contains the project title
			 * @returns {String}
			 */
			title() {
				return this.project.title;
			},
			/**
			 * An ugly hack that is supposed to check if the device uses iOS
			 * It's used because of this issue:
			 * https://apple.stackexchange.com/questions/130822/safari-ios-menu-bar-conflicts-with-buttons-in-lower-10-of-the-screen
			 * @returns {Boolean}
			 */
			isIOS() {
				const ua = window.navigator.userAgent;
				const iOS = /iP(ad|hone)/i.test(ua);
				return iOS;
			}
		},
		methods: {
			/**
			 * Bootstraps the modal before its opened
			 * @param {Object} e
			 */
			onBeforeOpen(e) {
				this.project = e.params;
			},
			/**
			 * Does some cleanup before closing the modal
			 * @param {Object} e
			 */
			onBeforeClose(e) {
				//redirect back to the main route when the modal closes
				this.$router.push('/');

				if (!e.ref) {
					return;
				}

				//manually add the "hide-scroll" class to the vm--container before closing the modal in order to prevent displaying 2 scroll bars at once
				e.ref.parentNode.classList.add('hide-scroll');
			},
			/**
			 * Closes the modal
			 */
			closeModal() {
				this.$emit('close');
			}
		}
	};
</script>

<style lang="scss">
	.project-info-modal {
		/** a bunch of hack fixes for the vue-js-modal component in scrollable mode **/
		.hide-scroll {
			overflow: hidden;
		}

		.vm--container {
			overflow-x: hidden;
		}

		.vm--modal {
			height: auto !important;
			width: 100% !important;
			max-width: 900px;
		}
		/** end hack fixes **/

		.vm--overlay {
			background: rgba($gray-dark, 0.4);
		}

		.vm--modal {
			border-radius: 0px;
		}

		.modal-content {
			background-color: $gray-medium-dark;
			border: solid 5px transparent;

			.project-title {
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				padding: 10px;
				background-color: #606060;
				margin-bottom: 10px;
			}

			.buttons-wrapper {
				margin: 20px 0px 20px 0px;

				&.apple-sucks {
					margin-bottom: 120px;
				}

				.close-btn {
					display: block;
					margin: auto;
					padding: 10px 15px;
					background-color: $gray-medium;
					color: $white;
					border: none;
					font-size: 16px;
					font-weight: bold;
					transition: all 0.4s ease-in-out;
					cursor: pointer;

					&:hover, &:active, &:focus, &.active {
						background-color: darken($gray-medium, 10%);
					}
				}
			}
		}
	}
</style>
