import { render, staticRenderFns } from "./TransparentButton.vue?vue&type=template&id=c94f0968&scoped=true&"
var script = {}
import style0 from "./TransparentButton.vue?vue&type=style&index=0&id=c94f0968&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c94f0968",
  null
  
)

export default component.exports