<template>
	<div class="main-view">
		<AnimatedBackground />

		<Intro
			@show-projects="scrollToProjects"
		/>

		<ProjectsList
			ref="projects"
			:projects="projects"
			:filter="filter"
			@filter-projects="filterProjects"
			@open-project="onOpenProject"
		/>

		<!-- the project/modal view gets injected here -->
		<router-view></router-view>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapActions } from 'vuex';

	import AnimatedBackground from '@/components/AnimatedBackground';
	import Intro from '@/components/Intro';
	import ProjectsList from '@/components/projects-list/ProjectsList';

	export default {
		components: {
			AnimatedBackground,
			Intro,
			ProjectsList
		},
		computed: {
			...mapGetters([
				'projects'
			]),
			...mapState([
				'filter'
			])
		},
		methods: {
			...mapActions([
				'filterProjects'
			]),
			/**
			 * Opens the "project" route with the provided project id/class/unique identifier
			 * @param {Object} project
			 */
			onOpenProject(project) {
				this.$router.push({
					name: 'project',
					params: {
						id: project.class
					}
				});
			},
			/**
			 * Scroll the window to the projects page
			 */
			scrollToProjects() {
				if (!this.$refs.projects) {
					return;
				}

				this.$refs.projects.$el.scrollIntoView({ behavior: 'smooth' });
			}
		}
	};
</script>
