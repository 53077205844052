<template>
	<div class="splide-carousel" ref="carousel">
		<div class="splide__track">
			<div class="splide__list">
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
	import Splide from '@splidejs/splide';
	import '@splidejs/splide/dist/css/splide.min.css';

	export default {
		props: {
			options: {
				type: Object,
				default() {
					return {};
				}
			}
		},
		/**
		 * Creates a new splide carousel instance
		 */
		mounted() {
			new Splide(this.$refs.carousel, this.options).mount();
		}
	};
</script>

<style lang="scss">
	.splide-carousel {
		position: relative;
		border: solid 1px $gray-medium;

		.splide__slide {
			height: 60vw;
			max-height: 532px;
		}

		.splide__arrow {
			padding: 10px;
			width: auto;
			height: auto;
			background: rgba($black, 0.4);
			border-radius: 0px;

			svg {
				width: 60px;
				height: 60px;
				fill: $white;
			}
		}

		.splide__pagination {
			align-items: center;

			li {
				display: flex;
				justify-content: center;
				align-items: center;
				min-height: 26px;
			}

			.splide__pagination__page {
				width: 28px;
				height: 10px;
				border-radius: 0px;
				transform: scale(1);
				border: solid 1px $white;
				transition: all 0.3s ease-out;

				&.is-active, &:hover {
					padding: 6px 0px;
					background: $gray-dark;
					border: solid 1px $gray-light;
					opacity: 1;
				}
			}
		}

		@media (max-width: $small) {
			.splide__arrow {
				display: none;
			}
		}
	}
</style>
