<template>
	<div id="animated-background"></div>
</template>

<script>
	import 'particles.js';

	//ugly particles.js hack that replaces the library's "deepExtend" function that throws an error under strict mode
	//https://github.com/VincentGarreau/particles.js/issues/123
	function deepExtendFunction(destination, source) {
		for (const property in source) {
			if (source[property] && source[property].constructor && source[property].constructor === Object) {
				destination[property] = destination[property] || {};
				deepExtendFunction(destination[property], source[property]);
			} else {
				destination[property] = source[property];
			}
		}
		return destination;
	}

	Object.deepExtend = deepExtendFunction;

	export default {
		/**
		 * Initializes the BackgroundAnimation
		 */
		mounted() {
			window.particlesJS.load('animated-background', '/particles-config.json', () => {
				this.$emit('ready');
			});
		}
	};
</script>

<style lang="scss" scoped>
	#animated-background {
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: $gray-dark;
	}
</style>
