<template>
	<div class="project-description">
		<div class="project-development">
			Development: {{ project.development }}
		</div>

		<component
			:is="project.class"
			:class="['project-summary', project.class]"
		/>

		<div class="project-technologies">
			<div>Technologies used:</div>
			<ul>
				<li
					v-for="technology in project.technologies"
					:key="technology"
				>
					{{ technology }}
				</li>
			</ul>
		</div>

		<div
			v-if="hasLinks"
			class="project-links"
		>
			<IconLink
				v-if="project.source"
				:href="project.source"
				title="View source code"
				target="_blank"
			>
				<font-awesome-icon :icon="['fab', 'github']" />
			</IconLink>

			<IconLink
				v-if="project.link"
				:href="project.link"
				title="Open project"
				target="_blank"
			>
				<font-awesome-icon :icon="['fas', 'external-link-alt']" />
			</IconLink>
		</div>
	</div>
</template>

<script>
	import IconLink from '@/components/IconLink';

	export default {
		components: {
			IconLink
		},
		props: {
			project: {
				type: Object,
				required: true
			}
		},
		computed: {
			/**
			 * Indicates whether the project has a link or source link
			 * @returns {Boolean}
			 */
			hasLinks() {
				return this.project.link || this.project.source;
			}
		}
	};
</script>

<style scoped lang="scss">
	.project-description {
		padding: 10px 10px 0px 10px;
		font-size: 18px;

		::v-deep ul {
			margin: 0px;
			padding: 15px 40px;

			li {
				margin-bottom: 3px;
			}
		}

		.project-development {
			margin-bottom: 15px;
			font-weight: bold;
		}

		.project-technologies {
			margin-top: 15px;

			> div {
				font-weight: bold;
			}
		}

		.project-links {
			text-align: center;

			.icon-link {
				& + .icon-link {
					margin-left: 10px;
				}
			}
		}
	}
</style>
