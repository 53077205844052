<template>
	<div
		class="projects-list-item"
		:style="{
			backgroundImage: `url(${backgroundImage})`,
			borderColor
		}"
		:title="project.title"
		@click="onClick"
	>
		<div class="title">
			{{ project.title }}
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			project: {
				type: Object,
				required: true
			}
		},
		computed: {
			/**
			 * Contains the default project image
			 * @returns {String}
			 */
			backgroundImage() {
				if (!this.project.images) {
					return '';
				}

				return this.project.images[0];
			},
			/**
			 * Returns the project border color
			 * @returns {String}
			 */
			borderColor() {
				return this.project.background;
			}
		},
		methods: {
			/**
			 * Emits the click event with the selected project data
			 */
			onClick() {
				this.$emit('click', this.project);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.projects-list-item {
		width: 100%;
		height: 180px;
		background-size: cover;
		border: solid 5px transparent;
		cursor: pointer;

		.title {
			padding: 5px;
			background-color: rgba($gray-dark, 0.9);
			color: $white;
			text-align: center;
			font-weight: bold;
			transition: all 0.2s ease;
		}

		&:hover {
			.title {
				padding: 73px 0px;
				font-size: 20px;
			}
		}
	}
</style>
